import { mixPanelEvent } from '@features/activities/data';
import {
  PreOrderInternalProductsListParams,
  PreOrderProductsListData,
  PreOrderUseCaseResolver,
} from '@features/pre-order/domain';
import { EventWithType } from '../../base/base.presenter';
import { FetchPresenter } from '../../base/fetch.presenter';
import { StatefulState } from '../../base/stateful.presenter';
import { VIEW_PREORDERABLE_PRODUCTS_PAGE } from '../../shared/constants/mixpanel';

export type PreOrderProductsViewState = StatefulState<PreOrderProductsListData, string>;

export type PreOrderProductsSideEffect = {
  type: 'ProductsLoaded';
  data: PreOrderProductsListData;
};

export class PreOrderProductsPresenter extends FetchPresenter<
  PreOrderProductsViewState,
  EventWithType,
  PreOrderProductsSideEffect
> {
  public queryParams: PreOrderInternalProductsListParams = {
    page: 1,
    pageSize: 12,
    sortBy: 'introducedAt',
    commercialCategoryId: undefined,
  };

  /**
   * Start loading  products pre orders
   */
  public fetch(params: PreOrderInternalProductsListParams = this.queryParams): void {
    this.startLoading();

    this.queryParams = params;

    PreOrderUseCaseResolver.listProducts(params)
      .then((result) => {
        this.success(result);

        this.emitSideEffect({
          type: 'ProductsLoaded',
          data: result,
        });

        mixPanelEvent(VIEW_PREORDERABLE_PRODUCTS_PAGE);
      })
      .catch((error) => {
        this.toastFailed(error);
      });
  }

  public gotoPage(page: number): void {
    this.fetch({
      ...this.queryParams,
      page,
    });
  }

  public sortBy(sortBy: string): void {
    if (sortBy === this.queryParams.sortBy) return;
    this.fetch({
      ...this.queryParams,
      page: 1,
      sortBy,
    });
  }

  public limit(limit: number): void {
    this.fetch({
      ...this.queryParams,
      page: 1,
      pageSize: limit,
    });
  }

  public filterByCategory(commercialCategoryId: string): void {
    this.fetch({
      ...this.queryParams,
      page: 1,
      commercialCategoryId,
    });
  }
}
