<p class="page-title">
  {{ pageTitle }}
</p>

<div id="pre-order-products">
  <div class="row wrapper">
    <!-- Sidebar -->
    <div class="col-12 col-lg-3 col-md-3 item d-none d-lg-block">
      <app-level-one-categories-sidebar
        *ngIf="isCategoriesLoaded && categoryHierarchyHasLessThanTwoLevels"
        [categoriesNodes]="levelOneCategoriesNodes"
        [selectedCategory]="selectedCategoryName"
        [queryParamsObject]="presenter.queryParams"
        [isPreOrderable]="true"
        (emittedCategoryId)="getSelectedCommercialCategories($event)"
        [isNewPreOrderEnabled]="true"
      ></app-level-one-categories-sidebar>

      <app-categories-sidebar
        #categoriesSidebar
        *ngIf="isCategoriesLoaded && !categoryHierarchyHasLessThanTwoLevels"
        [selectedCategoryName]="selectedCategoryName"
        [selectedCategoryHierarchy]="categoryHierachy"
        [isPreOrderable]="true"
        (emittedCategoryId)="getSelectedCommercialCategories($event)"
        [isNewPreOrderEnabled]="true"
      ></app-categories-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-md-9" *ngIf="presenter.isLoading">
      <loader [loading]="true" size="md" alt="loading" id="loading"></loader>
    </div>
    <div class="col-12 col-lg-9 col-md-9" *ngIf="presenter.isLoaded">
      <app-subcategory-filter
        *ngIf="categoryHierachyHasFourlevels"
        [fourthLevelCategoriesList]="fourthLevelCategoriesList"
      ></app-subcategory-filter>
      <div class="d-flex flex-wrap justify-content-between">
        <div class="category-heading">
          <span>({{ this.presenter.data?.count }} {{ trans('CATEGORY_PAGE.RESULTS') }})</span>
        </div>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="btn-group" dropdown>
            <span class="items-text">{{ trans('CATEGORY_PAGE.SHOW') }}</span>
            <button
              id="button-animated"
              dropdownToggle
              type="button"
              class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-animated"
            >
              <span class="selected-items-per-page">
                12 {{ trans('CATEGORY_PAGE.PER_PAGE') }}
              </span>
              <i class="icon icon-dropdown" aria-hidden="true"></i>
            </button>
            <ul
              id="dropdown-animated"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-animated"
            >
              <li
                *ngFor="let pageFilterSize of pagesFilterSize"
                role="menuitem"
                class="dropdown-item"
                (click)="presenter.limit(pageFilterSize)"
              >
                {{ pageFilterSize }}
              </li>
            </ul>
          </div>
          <div class="btn-group" dropdown>
            <span class="items-text">{{ trans('CATEGORY_PAGE.SORT_BY') }}</span>
            <button
              id="button-animated"
              dropdownToggle
              type="button"
              class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-animated"
            >
              <span class="selected-sorting">{{ trans(selectedSortBy.translationKey) }}</span>
              <i class="icon icon-dropdown" aria-hidden="true"></i>
            </button>
            <ul
              id="dropdown-animated"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-animated"
            >
              <li
                role="menuitem"
                class="dropdown-item"
                *ngFor="let sortingOption of sortingOptions"
                (click)="changeSorting(sortingOption.value)"
              >
                {{ trans(sortingOption.translationKey) }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 d-flex flex-wrap justify-content-flex-start">
        <app-product-card
          *ngFor="let product of presenter.data?.products"
          [variantGroup]="product"
          [isFromLockedCarousel]="false"
          [shouldShowStockDistribution]="product.primaryVariant?.availabilityInfo?.stockInfo"
        ></app-product-card>
      </div>
      <div
        *ngIf="presenter.data?.count > 0"
        class="d-flex flex-column justify-content-center mb-5 mt-5 paginate"
      >
        <div class="pagination-wrapper d-flex justify-content-center">
          <pagination
            [totalItems]="presenter.data?.count"
            [itemsPerPage]="presenter.queryParams.pageSize"
            [(ngModel)]="currentPage"
            [maxSize]="presenter.queryParams.pageSize"
            (pageChanged)="presenter.gotoPage($event.page)"
            previousText=" "
            nextText=" "
          >
          </pagination>
        </div>
      </div>
    </div>
  </div>
</div>
